const slick = require('slick-carousel');
let main_index = 0;

function rtl_slick() {
    if ($('body').hasClass("eg")) {
        return true;
    } else {
        return false;
    }
}
if ($('.homepage-banner').length) {
    $('.homepage-banner').slick({
        autoplay: true,
        // autoplaySpeed: 11500,
        // speed: 300,
        autoplaySpeed: 18000,
        speed: 700,
        lazyLoad: 'ondemand',
        infinite: true,

        arrows: false,
        dots: true,
        pauseOnHover: false,
        fade: true,
        rtl: rtl_slick(),
    });

    // $('.homepage-banner').on('wheel', (function(e) {
    //     e.preventDefault();
    //     if (e.originalEvent.deltaY < 0) {
    //         $(this).slick('slickNext');
    //     } else {
    //         $(this).slick('slickPrev');
    //     }
    // }));

    $('.page-container').css({
        'padding-bottom': '0px'
    });
}

if ($('.our-clients-slick ').length) {
    $('.our-clients-slick').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1350,
        infinite: true,

        // dots: true,

        // infinite: false,
        rtl: rtl_slick(),
        responsive: [{
                breakpoint: 1500,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                    // arrows: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                    //arrows: false,
                }
            }
        ]
    });
}

// if ($('.slick-news-item').length) {
//     $('.slick-news-item').slick({
//         infinite: false,
//         lazyLoad: 'ondemand',
//         responsive: [{
//             breakpoint: 768,
//             settings: {
//                 dots: true,
//                 arrows: false,
//             }
//         }]
//     });
// }

if ($('.sec-homepage-news-events').length) {

    $('.slick-news-item-text').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slick-news-item-img',
        rtl: rtl_slick(),
    });
    $('.slick-news-item-img').slick({
        infinite: false,
        lazyLoad: 'ondemand',
        rtl: rtl_slick(),
        responsive: [{
            breakpoint: 768,
            settings: {
                dots: true,
                arrows: false,
            }
        }],
        // slidesToShow: 3,
        // slidesToScroll: 1,
        asNavFor: '.slick-news-item-text',
        // dots: true,
        // centerMode: true,
        // focusOnSelect: true
    });

}

// certificates

if ($('.sec-certificates').length) {
    $('.sec-certificates .slider-certificates').slick({
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        infinite: true,
        speed: 300,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 3000,
        focusOnSelect: true,
        rtl: rtl_slick(),
        responsive: [{
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    centerPadding: '50px',
                    slidesToShow: 1
                }
            }
        ]
    });
}



// Photo Gallery



if ($('.photo-gallery').length) {


    let ActiveGalleryView = false;

    let ActiveGallerywork = false;


    $('.wrapper-photo-gallery').click(function() {
		let ActiveGallery = $(this);
		$('.photoGalleryModal .gallery').css('opacity', '0')
        main_index = $(this).parent('.main-img').index();

        // console.log(thisGalleryWrapp);

        //if (!ActiveGalleryView) {

       if (ActiveGallerywork) {
							   $('.photoGalleryModal .gallery').slick('slickGoTo', main_index);
							   $('.photoGalleryModal .gallery').css('opacity', '1')

	   }

        $('.photoGalleryModal').modal('show');



    });


    $('.photoGalleryModal').on('shown.bs.modal', function(e) {

        $('.photoGalleryModal .gallery').on('init', function(event, slick) {
            ActiveGallerywork = true;
        });
        if (!ActiveGallerywork) {

            $('.photoGalleryModal .gallery').slick({
				infinite: false,
				  lazyLoad: 'ondemand',

                speed: 500,
                fade: true,
                // autoplay: true,
                rtl: rtl_slick(),
                cssEase: 'linear',
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        arrows: false,
                    }
                }]
			})
			        $('.photoGalleryModal .gallery').slick('slickGoTo', main_index);
			$('.photoGalleryModal .gallery').css('opacity', '1')

        }

    })

}


if ($('.news-events-details-images').length) {
    $('.news-events-details-images').slick({
        dots: true,
        arrows: false,
        speed: 700,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2500,
        rtl: rtl_slick(),
    });
}


if ($('.images-slider').length) {
    $('.images-slider').slick({
        dots: true,
        arrows: false,
        speed: 700,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2500,
        rtl: rtl_slick(),
    });
}





//

//
if ($('.lg-column-slick').length) {
    $('.lg-column-slick').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 1350,
        // infinite: true,

        // dots: true,

        // infinite: false,
        rtl: rtl_slick(),
        responsive: [{
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,


                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                    // arrows: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    });
}




//
if ($('.sm-column-slick').length) {
    $('.sm-column-slick').slick({
        slidesToShow: 6,
        slidesToScroll: 6,
        // autoplay: true,
        // autoplaySpeed: 1350,
        // infinite: true,

        // dots: true,

        // infinite: false,
        rtl: rtl_slick(),
        responsive: [{
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,


                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                    // arrows: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    });
}



//