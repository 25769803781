/* src/app.js */
/* eslint-disable */

// Styles
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/styles/main-ltr.scss";


//Scriptsbootstrap-slider.scss
import 'bootstrap';
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/dropdown';
// import Swiper from 'swiper';
// $(document).ready(() => {
require('./assets/scripts/animate-scroll');
require('./assets/scripts/e-motion-validate');
require('./assets/scripts/slick');
// require('./assets/scripts/jquery-parallax');
// require('./assets/scripts/progressbar');
require('./assets/scripts/bootstrap-select');
require('./assets/scripts/google_map');
require('./assets/scripts/bootstrap-datepicker');

require('./assets/scripts/custom');
require('./assets/scripts/employeeSystem');


// });
