if ($('.sec-employeeSystem-login').length) {
    $('.employeeSystem-container').css("margin-left", "0px")
    $('.employeeSystem-header').addClass('d-none')
}

if ($('.sec-employeeSystem-dashboard').length) {
    // console.log($('.sec-employeeSystem-dashboard .wrap-title').outerHeight())

    let heightWrapTitle = $('.sec-employeeSystem-dashboard .wrap-title').outerHeight();
    $('.sec-employeeSystem-dashboard .container-height').css({
        'height': 'calc(100vh - ' + heightWrapTitle + 'px - 4.5rem)'
    });

    $('.announcements-item-list').click(function() {
        $('.announcementModal').modal('show')
    });
    $('.inquires-item-list').click(function() {
        $('.complaintModal').modal('show')
    });
    if ($(".scroll-bar").length) {
        $(".scroll-bar").mCustomScrollbar();
    }

    // heightCardHeader = $('.sec-employeeSystem-dashboard .card-header').innerHeight(),
    // TotalHeight = 0;
    // heightmainTitle = $('.sec-employeeSystem-dashboard .main-title').outerHeight();


    // $(".sec-employeeSystem-dashboard .right-side-accordion .card-header").each(function() {
    //     alert($(this).height())
    //     TotalHeight += $(this).outerHeight();
    //     // TotalHeight += Number($(this).innerHeight());

    // });
    // alert(TotalHeight)



    // $('.sec-employeeSystem-dashboard .right-side-accordion .card-body').css({
    //     'height': 'calc(100vh - ' + TotalHeight + 'px)'
    // });

    // function scrollbar() {
    //     $(".scroll-bar").mCustomScrollbar();
    //     // $(".filter-results-scroll").mCustomScrollbar();

    // }
    // scrollbar();
    // $('.sec-employeeSystem-dashboard .height-box').css({
    //     'height': 'calc(100% - ' + heightmainTitle + '%)'
    // });

    // Scroll
    // function scrollbar() {
    //     $(".scroll-bar").mCustomScrollbar();
    // }



}