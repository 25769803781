require("jquery-mousewheel");
require('malihu-custom-scrollbar-plugin');
// Side Menu
var vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty('--vh', `${vh}px`);

if ($('.side-menu ').length) {


	$('.submenu-item .submenu-link').click(function (event) {
		event.preventDefault();
		event.stopPropagation();
	});
	$('.submenu-item .submenu-link').click(function () {
		subLinkItem = $(this);
		///	$('.submenu-item .close-submenu').remove();
		$('.submenu-item').removeClass('active-submenu');
		//subLinkItem.parent().append('<div class="close-submenu"></div>');
		// setTimeout(function() {
		subLinkItem.parent().addClass('active-submenu');
		// }, 300);
		$('.btn-search').parent().removeClass('active')
		$('.search-container ').removeClass('open-search');


	});

	$(".submenu-item").delegate(".close-submenu", "click", function () {
		$('.submenu-item').removeClass('active-submenu');
		// console.log($(this));
		// $(this).parent().removeClass('active-submenu');
		//$(this).remove();
		$('.btn-search').parent().removeClass('active')
		$('.search-container ').removeClass('open-search');

	});

	$('.page-container').click(function (event) {
		$('.submenu-item').removeClass('active-submenu');
		//$(".close-submenu").remove();


		$('.header').removeClass('mobile-menu-toggle')
		$('.header').parents('body').find('.page-container').removeClass('page-container-overlay')
	});

	// alert();

	// $('.social-icon')

}


// alert($('.circle-img-larg').innerWidth());

function calcCircleImage() {
	$('.circle-img-larg').each(function (i, obj) {
		$(this).css({
			'height': $(this).innerWidth() + 'px'

		});
		$(this).parent().find('.circle-img-small').css({
			'height': $(this).parent().find('.circle-img-small').innerWidth() + 'px'
		});
	});

}
if ($('.wrap-circle-image').length) {
	// calcCircleImage();
	setTimeout(function () {
		calcCircleImage();
	}, 500);

}

// if ($('.parallax-window').length) {
//     $('.parallax-window').parallax();

// }

// Resize Function

if ($('.more-content').length) {

	$(".more-content").each(function () {


		var content = $(this).html();
		if (content.length > showChar) {
			var c = content.substr(0, showChar);
			var h = content;
			var html =
				'<div class="truncate-text" style="display:block">' +
				c +
				'<span class="moreellipses">' +
				ellipsestext +
				'&nbsp;&nbsp;<a href="" class="moreless more colored">' + moretext + '</a></span></span></div><div class="truncate-text" style="display:none">' +
				h +
				'<a href="" class="moreless less colored">' + lesstext + '</a></span></div>';

			$(this).html(html);
		}
	});

	$(".more-content .moreless").click(function () {
		var thisEl = $(this);
		var cT = thisEl.closest(".truncate-text");
		var tX = ".truncate-text";

		if (thisEl.hasClass("less")) {
			cT.prev(tX).toggle();
			cT.slideToggle();



			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $(".moreellipses .moreless").offset().top
				}, 500);
			}, 0);


		} else {
			cT.toggle();
			cT.next(tX).fadeToggle();
		}
		return false;
	})
	/* end iffe */




}
if ($('.datepicker').length) {
	var $this = $('.datepicker');

	// console.log('again again ddddd')
	if ($('body.ar').length) {
		$('.datepicker').datepicker({
			language: 'ar',
			format: 'mm/dd/yyyy',
			autoclose: true
		});
	} else {
		$('.datepicker').datepicker({
			language: 'en',
			format: 'mm/dd/yyyy',
			autoclose: true,
		}).on('changeDate', function (e) {
			$this.addClass('active');

		});
	}


}

function initmenuHeight() {
	if ($('.side-menu ').length) {
		let navBrandHeight = $('.side-menu .nav-brand').innerHeight();

		// $('.side-menu .submenu').css({
		//     'padding-top': 'calc(' + navBrandHeight + 'px + 2.5rem)'
		// });

		$('.side-menu .submenu').css({
			'padding-top': 'calc(' + navBrandHeight + 'px )'
		});

		$('.search-container').css({
			'padding-top': 'calc(' + navBrandHeight + 'px )'
		});




		// socialIconHeight = $('.side-menu .social-icon').innerHeight() + navBrandHeight + 120;
		socialIconHeight = $('.side-menu .social-icon').innerHeight() + navBrandHeight + 30;
		// alert(socialIconHeight)
		$('.side-menu .first-menu').css({
			'height': 'calc(100vh - ' + socialIconHeight + 'px)'
		});
	}
	if ($('.mobile-menu').length) {

		navBrandHeightMobile = $('.mobile-menu .nav-brand-mobile').innerHeight();
		socialIconHeightMobile = $('.mobile-menu .social-icon').innerHeight() + navBrandHeightMobile + 50;
		// alert(socialIconHeight)
		$('.mobile-menu .mobile-menu-list').css({
			'height': 'calc(100vh - ' + socialIconHeightMobile + 'px)'
		});
	}

}
initmenuHeight();
$(window).resize(function () {
	calcCircleImage();

	vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	initmenuHeight();
});

if ($('[data-toggle="tooltip"]').length) {
	$('[data-toggle="tooltip"]').tooltip()
}
// $(window).scroll(function() {
//     $('.header').css({
//         'padding-bottom': $('.footer').innerHeight() + 'px'
//     });
//     if ($(window).scrollTop() + $(window).height() == $(document).height()) {
//         // alert("bottom!");

//         $('.header').css({
//             'padding-bottom': $('.footer').innerHeight() + 'px'
//         });
//     } else {
//         $('.header').css({
//             'padding-bottom': 2.5 + 'rem'
//         });
//     }


// });


if ($('.media-videos').length) {
	$('.media-videos').click(function () {
		// alert('aaa');
		var videosrc = $(this).attr('videosrc');
		$('.videoModal').modal('show');
		$('.videoModal iframe').attr('src', videosrc);

	})
	$('.videoModal').on('hidden.bs.modal', function (e) {
		$('.videoModal iframe').attr('src', '');
	})

}

if ($('.media-image').length) {
	$('.media-image').click(function () {
		// alert('aaa');
		var imgsrc = $(this).attr('imgsrc');
		$('.mediaModal').modal('show');
		$('.mediaModal .img-needed').attr('src', imgsrc);

	})
	$('.mediaModal').on('hidden.bs.modal', function (e) {
		$('.mediaModal .img-needed').attr('src', '');
	})

}





if ($('.search-container ').length) {

	$('body').click(function () {
		$('.btn-search').parent().removeClass('active')
		$('.search-container ').removeClass('open-search');
		searchItemHide();
	});

	$('.search-container .close-rounded').click(function (e) {
		e.stopPropagation();
		$('.btn-search').parent().removeClass('active')
		$('.search-container ').removeClass('open-search');

		searchItemHide();
	});

	$('.search-container').click(function (e) {
		e.stopPropagation();
		// searchItemHide();
	});
	$('.btn-search').click(function (event) {
		event.preventDefault();
		event.stopPropagation()
		$('.btn-search').parent().addClass('active')
		$('.search-container ').addClass('open-search');
		searchItemShow();
		$('.submenu-item').removeClass('active-submenu');
		//$(".close-submenu").remove()
	});

	function searchItemShow() {
		$(".search-item").each(function (i) {
			// store the item around for use in the 'timeout' function
			var $item = $(this);
			// execute this function sometime later:
			setTimeout(function () {
				$item.animate({
					"opacity": 1
				}, 300);
			}, 300 * i);
			// each element should animate half a second after the last one.
		});
	}

	function searchItemHide() {
		$(".search-item").each(function (i) {
			// store the item around for use in the 'timeout' function
			var $item = $(this);
			// execute this function sometime later:
			setTimeout(function () {
				$item.animate({
					"opacity": 0
				}, 50);
			}, 10 * i);
			// each element should animate half a second after the last one.
		});
	}
}


$('.form-control').blur(function () {
	if ($(this).val().length === 0) {
		// $(this).parents('p').addClass('warning');
		// alert('111')
		$(this).removeClass('active')
	} else {
		// console.log($(this).val());
		$(this).addClass('active')

	}
});

// Custom File
// $('input[type="file"]').change(function(e) {
//     var fileName = e.target.files[0].name;
//     // console.log($('.custom-file-label').html(fileName);)
//     $('.custom-file-label').attr('data-browse', fileName);
// });

$('.custom-file-input').change(function (e) {
	var fileName = e.target.files[0].name;
	// console.log($(this).html(fileName))
	$(this).parent().find('.custom-file-label').attr('data-browse', fileName);
});


// Scroll
function scrollbar() {
	$(".scroll-bar").mCustomScrollbar();
}

function destroyScrollbar() {
	$(".scroll-bar").mCustomScrollbar('destroy');
}

if ($(".scroll-bar").length) {
	// console.log($(".scroll-bar .form-check").length);
	if ($(".scroll-bar .form-check").length >= 6) {
		// alert('aaa');
		scrollbar();
	} else {
		destroyScrollbar();
	}
}


if ($('.error404').length) {
	$('.header').remove()
	$('.error404').parents('.page-container').removeClass('offset-lg-2')
}


if ($('.radio-btn').length) {
	$('.radio-btn').click(function () {
		$(this).parent().find('.form-check-input').prop('checked', 'true')
	})
}
// $('.scroll-down').click(function(e) {
//     $('html, body').animate({
//         scrollTop: $('#' + $(this).data('scroll')).offset().top
//     }, 1200);
// });




if ($('.offline-message').length) {

	$('body').click(function () {
		$('.offline-message .offline-message-form').removeClass('show');
		$('.offline-message .offline-message-img').removeClass('hide')
	});

	$('.offline-message .offline-message-img').click(function (event) {
		event.stopPropagation()
		$(this).addClass('hide');
		$('.offline-message .offline-message-form').addClass('show');


		$('.header').removeClass('mobile-menu-toggle')
		$('.header').parents('body').find('.page-container').removeClass('page-container-overlay')


	});

	$('.offline-message .offline-message-form .close-rounded').click(function (event) {
		event.stopPropagation()
		$('.offline-message .offline-message-form').removeClass('show');
		$('.offline-message .offline-message-img').removeClass('hide')
	});
	$('.offline-message .offline-message-form').click(function (event) {
		event.stopPropagation()
	});

}

// Mobile Menu Toggle
$('.icon-toggle-mobile-menu').click(function () {
	// alert('h')
	$('.header').toggleClass('mobile-menu-toggle')
	$('.header').parents('body').find('.page-container').toggleClass('page-container-overlay')
})


if ($('.border-left-animate').length) {
	$('.border-left-animate').parent().addClass('position-relative')
}
if ($('.border-right-animate').length) {
	$('.border-right-animate').parent().addClass('position-relative');
}


// Scroll collapse
$('.collapse').on('shown.bs.collapse', function (e) {
	var $card = $(this).closest('.card');
	$('html,body').animate({
		scrollTop: $card.offset().top - 15
	}, 500);
});



// Scroll Section


$(document).ready(function () {
	if ($('body').hasClass('eg')) {
		if ($('.banner-btn-container').length) {
			$(this).find('.lang-en').removeClass('d-none');
			$(this).find('.lang-eg').addClass('d-none');
			// alert('arabic')
		}
	}
	setTimeout(function () {
		$('.loading_overlay').fadeOut();
	}, 200);

	$('.sechomepage-banner .scroll-down').click(function (e) {
		// console.log($(this).parents('.sechomepage-banner').next());
		$('html, body').animate({
			scrollTop: $(this).parents('.sechomepage-banner').next().offset().top
		}, 1200);
	});

	var queryString = window.location.search;
	// console.log('queryString', queryString);
	var urlParams = new URLSearchParams(queryString);



	var section_id = urlParams.get('section-id');
	// console.log('section_id', section_id)
	if ($('#' + section_id).length) {
		$('html, body').animate({
			scrollTop: $('#' + section_id).offset().top
		}, 1000);
	}
	setTimeout(function () {
		initmenuHeight();
	}, 300)

});
