const bootstrapSelect = require('bootstrap-select');

if ($('.selectpicker').length) {
    $('.selectpicker').selectpicker();
    $('.bootstrap-select .dropdown-toggle').append('<div class="select-arrow"></div>');

    $('.selectpicker').on('show.bs.select', function() {
        $(this).parents('.form-group').find('.input-underline').addClass('active');
        $(this).parents('.form-group').find('label').addClass('label-select');
        $(this).parents('.bootstrap-select').find('.select-arrow').addClass('active');
    });
    $('.selectpicker').on('hide.bs.select', function() {
        $(this).parents('.form-group').find('.input-underline').removeClass('active');
        $(this).parents('.form-group').find('label').removeClass('label-select');
        $(this).parents('.bootstrap-select').find('.select-arrow').removeClass('active');
    });
}